export const ENV_CONFIG_AUTH0_DOMAIN = process.env
  .REACT_APP_AUTH0_DOMAIN as string;
export const ENV_CONFIG_AUTH0_CLIENT_ID = process.env
  .REACT_APP_AUTH0_CLIENT_ID as string;
export const ENV_CONFIG_AUTH0_AUDIENCE = process.env
  .REACT_APP_AUTH0_AUDIENCE as string;

export const ENV_CONFIG_API_BASE_URL = process.env
  .REACT_APP_API_BASE_URL as string;
export const ENV_CONFIG_API_PM_BASE_URL = process.env
  .REACT_APP_API_PM_BASE_URL as string;
export const ENV_CONFIG_API_BMS_BASE_URL = process.env
  .REACT_APP_API_BMS_BASE_URL as string;
export const ENV_CONFIG_API_BMS_ECS_BASE_URL = process.env
  .REACT_APP_API_BMS_ECS_BASE_URL as string;
export const ENV_CONFIG_CLIENT_PORTAL_API_BASE_URL = process.env
  .REACT_APP_CLIENT_PORTAL_API_BASE_URL as string;

export const ENV_CONFIG_PUBLIC_POSTHOG_KEY = process.env
  .REACT_APP_PUBLIC_POSTHOG_KEY as string;
export const ENV_CONFIG_PUBLIC_POSTHOG_HOST = process.env
  .REACT_APP_PUBLIC_POSTHOG_HOST as string;
export const ENV_CONFIG_PUBLIC_POSTHOG_PROXY_API_HOST = process.env
  .REACT_APP_PUBLIC_POSTHOG_PROXY_API_HOST as string;
export const ENV_CONFIG_STREAM_CLIENT_ID = process.env
  .REACT_APP_STREAM_CLIENT_ID as string;
export const ENV_CONFIG_FLATIRONS_FUSE_ORG_API_KEY = process.env
  .REACT_APP_FLATIRONS_FUSE_ORG_API_KEY as string;
export const ENV_CONFIG_FLATIRONS_FUSE_IMPORT_CLIENT_TEMPLATE_ID = process.env
  .REACT_APP_FLATIRONS_FUSE_IMPORT_CLIENT_TEMPLATE_ID as string;
export const ENV_CONFIG_GMAIL_CLIENT_ID = process.env
  .REACT_APP_GMAIL_CLIENT_ID as string;
export const ENV_CONFIG_MICROSOFT_CLIENT_ID = process.env
  .REACT_APP_MICROSOFT_CLIENT_ID as string;
export const ENV_CONFIG_APRYSE_LICENSE_KEY = process.env
  .REACT_APP_APRYSE_LICENSE_KEY as string;

export const ENV_CONFIG_AADHAAR_SIGNATURE_FORM_ACTION_URL = process.env
  .REACT_APP_AADHAAR_SIGNATURE_FORM_ACTION_URL as string;

export const ENV_CONFIG_CLERK_PUBLISHABLE_KEY = process.env
  .REACT_APP_CLERK_PUBLISHABLE_KEY as string;

export const ENV_CONFIG_REACT_APP_STRIPE_PRICING_TABLE_ID = process.env
  .REACT_APP_STRIPE_PRICING_TABLE_ID as string;
export const ENV_CONFIG_REACT_APP_STRIPE_PUBLISHABLE_KEY = process.env
  .REACT_APP_STRIPE_PUBLISHABLE_KEY as string;

export const ENV_CONFIG_REACT_APP_ENVIRONMENT = process.env
  .REACT_APP_ENVIRONMENT as string;

export const ENV_REACT_APP_PDFJS_EXPRESS_UTILS_CLIENT_KEY = process.env
  .REACT_APP_PDFJS_EXPRESS_UTILS_CLIENT_KEY as string;

export const ENV_REACT_APP_PDFJS_EXPRESS_UTILS_SERVER_KEY = process.env
  .REACT_APP_PDFJS_EXPRESS_UTILS_SERVER_KEY as string;

export const ENV_CONFIG_REACT_APP_USECSV_KEY = process.env
  .REACT_APP_USECSV_KEY as string;

export const ENV_CONFIG_BUILD_APP_NAME =
  (process.env.REACT_APP_BUILD_APP_NAME?.trim() || undefined) as
    | ReactRootAppName
    | undefined;

export const ENV_CONFIG_INTERCOM_APP_ID = process.env
  .REACT_APP_INTERCOM_APP_ID as string;

export const ENV_CONFIG_UPLOADCARE_KEY = process.env
  .REACT_APP_UPLOADCARE_KEY as string;

export const ENV_CONFIG_UPLOADCARE_CDN_CNAME = process.env
  .REACT_APP_UPLOADCARE_CDN_CNAME as string;

export const ENV_CONFIG_CLIENT_PORTAL_LINK = process.env
  .REACT_APP_CLIENT_PORTAL_LINK as string;

export enum ReactRootAppName {
  Pm = 'PM',
  PmClient = 'PM_CLIENT_PORTAL',
}
